import axios from 'axios';

export async function getLocationData({ userId, lat, lng }) {
  try {
    const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
      headers: {
        'User-Agent': `skeddy/1.0 (${userId}@skeddy.com)`,
      },
      params: {
        format: 'jsonv2',
        lat,
        lon: lng,
        addressdetails: 1,
      },
    });

    return response.data;
  } catch (error) {
    return null;
  }
}
