import { Marker } from '../../types/marker';
import { Pin, SelectedObject } from '../../common/Map/interfaces';
import { makeCreateAction, makeCreateRoutine } from '../../helpers/actions';

const createAction = makeCreateAction('MAP');
const createRoutine = makeCreateRoutine('MAP');

export const deleteMarker = createRoutine('DELETE_MARKER', {
  request: (id: number) => ({ id }),
  success: (id: number, markers: Array<Marker>) => ({ id, markers }),
  failure: (id: number, status: number) => ({ id, status }),
});

export const updateMarker = createRoutine('UPDATE_MARKER', {
  request: (id: number, formData: FormData) => ({ id, formData }),
  success: (id: number, markers: Array<Marker>) => ({ id, markers }),
  failure: (id: number, status: number) => ({ id, status }),
});

export const createMarker = createRoutine('CREATE_MARKER', {
  request: ({ lat, lng }) => ({ lat, lng }),
  success: (markers: Array<Marker>) => ({ markers }),
});

export const getMarkers = createRoutine('GET_MARKERS', {
  success: (markers: Array<Marker>) => ({ markers }),
});

export const getClusters = createRoutine('GET_CLUSTERS', {
  request: (lat, lng) => ({ lat, lng }),
  success: (clusters) => ({ clusters }),
});

export const getPlacesBySearch = createRoutine('GET_PLACES_BY_SEARCH', {
  request: (search: string) => ({ search }),
  success: (places: any) => ({ places }),
});

export const clearPlaces = createAction('CLEAR_PLACES');

export const getBestRides = createRoutine('GET_BEST_RIDES', {
  success: (bestRides) => ({ bestRides }),
});

export const exitMap = createAction('EXIT_MAP');

export const getRadius = createRoutine('GET_RADIUS', {
  success: (radius: number) => ({ radius }),
});

export const setSelectedObject = createAction(
  'SET_SELECTED_OBJECT',
  (selectedObject: SelectedObject) => ({ payload: { selectedObject } })
);

export const setNewPin = createAction('SET_NEW_PIN', (newPin: Pin) => ({
  payload: { newPin },
}));

export const setDriverPin = createAction('SET_DRIVER_PIN', (driverPin: Pin) => ({
  payload: { driverPin },
}));

export const setIsLoading = createAction('SET_IS_LOADING', (isLoading: boolean) => ({
  payload: { isLoading },
}));

export const centerMap = createAction('CENTER_MAP');

export const toggleShowLayers = createAction('TOGGLE_SHOW_LAYERS');

export const toggleTopTip = createAction('TOGGLE_TOP_TIP');

export const setRoutes = createAction('SET_ROUTES', (routes) => ({
  payload: { routes },
}));

export const setMarkerForReplace = createAction(
  'SET_MARKER_FOR_REPLACE',
  (marker: Marker | null) => ({
    payload: { marker },
  })
);

export const toggleLayer = createAction(
  'TOGGLE_LAYER',
  (layer: 'bestRides' | 'drivers' | 'inactivePins' | 'bigClusters') => ({
    payload: { layer },
  })
);

export const actionCreators = {
  getMarkers,
  deleteMarker,
  createMarker,
  updateMarker,
  setDriverPin,
  exitMap,
  getRadius,
  setSelectedObject,
  setNewPin,
  setIsLoading,
  getClusters,
  centerMap,
  toggleShowLayers,
  toggleLayer,
  getBestRides,
  setRoutes,
  setMarkerForReplace,
  toggleTopTip,
  getPlacesBySearch,
  clearPlaces,
} as const;
