import moment from 'moment';

import i18n from '../../i18n';
import { Ride } from '../../types/ride';
import { RidesDestinationTime } from '../../store/interfaces';
import { FEATURE_IS_ALLOWED_OSM } from '../../config/default-features';

import { DANGER, SUCCESS, WARNING, UNKNOWN } from './RideRow/styled';

export const dayFormat = 'YYYY-MM-DD';

export const getPrintAddress = (address: string) =>
  address ? address.split(', ').slice(0, -1).join(', ') : '';

export const getPrintTime = (time: number) => moment.unix(time).format('h:mm A');

export const getMinutes = (time: number): number => Math.round(time / 60);

const timeToString = (time: number, isMin = false) => {
  const modTime = Math.abs(time);

  return time > 0 ? (isMin ? `+${modTime}` : `${modTime}+`) : `-${modTime}`;
};

const formatDiff = (minutes: number) => {
  if (minutes === 0) {
    return '';
  }

  const modDiff = Math.abs(minutes);

  if (modDiff > 60 * 24) {
    return i18n.t('rides.rideTimeDiff.day', { hours: minutes > 0 ? '1+' : '-1' });
  }

  if (modDiff > 60) {
    const hours = Math.round(minutes / 60);
    const modHours = Math.abs(hours);

    return i18n.t('rides.rideTimeDiff.hours', {
      hours: timeToString(hours / modHours),
    });
  }

  return i18n.t('rides.rideTimeDiff.min', { min: timeToString(minutes, true) });
};

export const getRideDestinationTimeIndex = (prevRideId: number, rideId: number): string =>
  `${prevRideId}-${rideId}`;

interface MinAndStatus {
  minutes: string | null;
  status: string | undefined;
}

export const getMinAndStatus = (
  ride: Ride,
  prevRide: Ride | undefined,
  ridesDestinationTime: RidesDestinationTime
): MinAndStatus => {
  if (!FEATURE_IS_ALLOWED_OSM) {
    return {
      minutes: null,
      status: UNKNOWN,
    };
  }

  if (prevRide === undefined) {
    const index = getRideDestinationTimeIndex(0, ride.id);
    const prevPickupTime = ridesDestinationTime[index] || 0;
    const diff = getMinutes(ride.pickup_time - (prevPickupTime + Date.now() / 1000));

    return {
      minutes: formatDiff(diff),
      status: diff > 0 ? SUCCESS : diff < -15 ? DANGER : WARNING,
    };
  }

  const destinationTimeIndex = getRideDestinationTimeIndex(prevRide.id, ride.id);
  let destinationTime = ridesDestinationTime[destinationTimeIndex];

  if (destinationTime === undefined) {
    return {
      minutes: null,
      status: UNKNOWN,
    };
  }

  destinationTime = destinationTime ?? 0;

  const diff = getMinutes(ride.pickup_time - (prevRide.end_time + destinationTime));
  const status = diff > 0 ? SUCCESS : diff < -15 ? DANGER : WARNING;

  return {
    minutes: formatDiff(diff),
    status,
  };
};

export const getDayTitle = (dayMoment: moment.Moment) => {
  const today = moment(new Date());
  const dayDate = dayMoment.format(dayFormat);

  if (today.format(dayFormat) === dayDate) {
    return i18n.t('rides.rowTitles.today');
  }

  if (today.add(1, 'days').format(dayFormat) === dayDate) {
    return i18n.t('rides.rowTitles.tomorrow');
  }

  return `${i18n.t('rides.rowTitles.totalFor')} ${dayMoment.format('ddd, MMM D')}`;
};

export const sortRides = (rides: Ride[], options = { field: 'pickup_time', isAsc: true }) => {
  const sortedRides = [...rides];
  const { field, isAsc } = options;

  sortedRides.sort((a, b) => {
    const valueA = parseFloat(a[field]);
    const valueB = parseFloat(b[field]);

    return isAsc ? valueA - valueB : valueB - valueA;
  });

  return sortedRides;
};
