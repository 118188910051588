import axios from 'axios';

import { baseUrl, lyftApiUrl } from '../config/urls';
import { Ride } from '../types/ride';
import { isAndroid } from '../helpers/os';
import { nativeMessageService } from '../services/NativeMessageService';
import storageService, { StorageKeys } from '../services/StorageService';
import i18n from '../i18n';

import { apiCallDecorator, generateAndroidIdentifiers } from './helpers';

const api = axios.create({
  timeout: 90000,
});

api.defaults.baseURL = baseUrl;

const urlObject = new URL(baseUrl);
const base = `${urlObject.protocol}//${urlObject.host}`;
const userAgent = 'user-agent';

const markers = {
  list: () =>
    api.get(
      '/v1/markers' /* {
      params: {
        withclusters: true,
        withdrivers: true,
        radius: 40000,
      },
    } */
    ),
  create: (locationData: object) =>
    api.post(
      '/v1/markers',
      locationData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      } /* {
      params: {
        withclusters: true,
        withdrivers: true,
        radius: 40000,
      },
    } */
    ),
  update: (id: number, formData: URLSearchParams) =>
    api.put(`/v1/markers/${id}`, formData, {
      /* params: {
        withclusters: true,
        withdrivers: true,
        radius: 40000,
      }, */
      headers: {
        'Content-Type': 'text/plain',
      },
    }),
  delete: (id: number) =>
    api.delete(
      `/v1/markers/${id}` /* , {
      params: {
        withclusters: true,
        withdrivers: true,
        radius: 40000,
      },
    } */
    ),
  setBackgroundLocation: (formData: URLSearchParams) =>
    api.put('/v1/online-markers', formData, {
      headers: {
        'Content-Type': 'text/plain',
      },
    }),
  clustersList: (lat: number, lng: number) =>
    api.get(`/v1/markers/clusters-nearby`, { params: { lat, lng, radius: 40000 } }),
};

const rides = {
  list: () => api.get('/v1/rides'),
  get: (id: number) => api.get(`/v1/rides/${id}`),
  delete: (id: number) => api.post(`/v1/rides/drop/${id}`),
  top: () => api.get('/v1/rides/top'),
  add: (formData: FormData) => api.post('/v1/rides/add', formData),
};

const androidConfig = {
  get: (timestamp = new Date().getTime()) =>
    axios.get(`/android.json?timestamp=${timestamp}`).then((res) => res.data),
};

const auth = {
  login: (params: URLSearchParams) => api.post('/v1/auth/login', params),
  registration: (formData: FormData) => api.post('/v1/auth', formData),
  agreed: () => api.post('/v1/auth/agreed'),
};

const settingsUrl = '/v1/filter';
const settings = {
  get: () => api.get(settingsUrl),
  set: (formData: FormData) => api.post(settingsUrl, formData),
};

const notifications = {
  subscribe: (token: string) => api.post(`/v1/system/device-token/${token}`),
};

const search = {
  set: (isSearchOn: boolean) => api.put(`/v1/search/${isSearchOn ? 'start' : 'stop'}`),
  get: () => api.get('/v1/search/status'),
};

const purchases = {
  validate: (formData: FormData) => api.post('/v1/payments', formData),
  list: (params: object) =>
    api.get(`/v1/payments`, {
      params,
    }),
};

const authV2 = {
  getParams: () => api.get('/v2/auth/params'),
  auth: ({
    phone_number,
    drivers_license_number,
    token_type,
    access_token,
    expires_in,
    refresh_token,
    user_id,
    extension_code,
  }) =>
    api.post('/v2/auth', {
      phone_number,
      drivers_license_number,
      token_type,
      access_token,
      expires_in,
      refresh_token,
      user_id,
      extension_code,
    }),
};

const lyftApi = {
  access_token: ({ headers, type, repeatCount = 0 }) => {
    const url = `${lyftApiUrl}/oauth2/access_token`;
    const config = {
      headers,
    };

    const formData = new FormData();
    formData.append('grant_type', 'client_credentials');

    if (isAndroid) {
      nativeMessageService.sendNetworkRequestMessage({
        url,
        headers: config.headers,
        params: Object.fromEntries(formData.entries()),
        method: 'post',
        repeatCount,
        type,
      });
      return Promise.resolve({});
    }

    return axios.post(url, formData, {
      headers: {
        authorization: headers.authorization,
        [userAgent]: headers[userAgent],
      },
    });
  },
  tcsConfig: ({ token_type, access_token, headers, type, params }) => {
    const url = `${lyftApiUrl}/v1/tcs-config`;
    const headersCopy = {
      ...headers,
    };

    headersCopy.authorization = `${token_type} ${access_token}`;

    if (isAndroid) {
      nativeMessageService.sendNetworkRequestMessage({
        url,
        headers: headersCopy,
        params,
        sendImmediate: true,
        method: 'post',
        type,
      });
    }
    return Promise.resolve({});
  },
  phoneAuth: ({ token_type, access_token, headers, phone, type }) => {
    const url = `${lyftApiUrl}/v1/phoneauth`;

    const headersCopy = {
      ...headers,
    };

    headersCopy.authorization = `${token_type} ${access_token}`;
    const config = {
      headers: headersCopy,
    };

    /* const data = {
      phone_number: `${phone}`, // из формы
      extend_token_lifetime: true,
      message_format: 'sms_basic',
    }; */

    const data = {
      phone_number: `${phone}`, // из формы
      voice_verification: false,
      message_format: 'sms_android_retriever',
      client_configuration: 'release',
    };

    if (isAndroid) {
      nativeMessageService.sendNetworkRequestMessage({
        url,
        headers: config.headers,
        params: data,
        method: 'post',
        type,
      });
      return Promise.resolve({});
    }

    return axios.post(url, data, {
      headers: {
        authorization: headersCopy.authorization,
        [userAgent]: headersCopy[userAgent],
      },
    });
  },

  token: ({ headers, phone, code, drivers_license_number = undefined, type, repeatCount = 0 }) => {
    const url = `${lyftApiUrl}/oauth2/access_token`;
    const config = {
      headers,
    };

    const formData = new FormData();
    formData.append('grant_type', 'urn:lyft:oauth2:grant_type:phone');
    formData.append('phone_number', phone);
    formData.append('phone_code', `${code}`);

    if (isAndroid) {
      const accountIdentifierToken = storageService.getAccountIdentifierToken();

      if (accountIdentifierToken) {
        formData.append(
          'identifiers',
          btoa(JSON.stringify(generateAndroidIdentifiers(accountIdentifierToken)))
        );
      }
    }

    if (drivers_license_number) {
      formData.append('drivers_license_number', `${drivers_license_number}`);
    }

    if (isAndroid) {
      nativeMessageService.sendNetworkRequestMessage({
        url,
        headers: config.headers,
        params: Object.fromEntries(formData.entries()),
        method: 'post',
        repeatCount,
        type,
      });
      return Promise.resolve({});
    }

    return axios.post(url, formData, {
      headers: {
        authorization: headers.authorization,
        [userAgent]: headers[userAgent],
      },
    });
  },
};

const getInformation = (formData: FormData) => api.post('/v1/system/helper', formData);

const sendPushNotification = (formData: FormData) =>
  api.post('/v1/system/test-push-notification', formData);

const routes = {
  get: (ride: Ride) => {
    const formData = new FormData();
    formData.append('data[service]', 'route');
    formData.append('data[data][sLat]', `${ride.start_lat}`);
    formData.append('data[data][sLng]', `${ride.start_lng}`);
    formData.append('data[data][fLat]', `${ride.end_lat}`);
    formData.append('data[data][fLng]', `${ride.end_lng}`);
    formData.append('data[data][params][overview]', 'full');
    formData.append('data[data][params][geometries]', 'geojson');
    formData.append('data[data][params][alternatives]', 'false');

    return getInformation(formData);
  },
};

const worker = {
  log: (data: object) =>
    api.post(
      `${base}/api/worker/v1/log`,
      { data },
      {
        baseURL: base,
      }
    ),
};

const apiList = [authV2, auth, settings, search, markers, rides, notifications, purchases, worker];

apiList.forEach((apiSection) => {
  Object.keys(apiSection).forEach((method) => {
    apiSection[method] = apiCallDecorator(apiSection[method]);
  });
});

api.interceptors.request.use((config) => {
  const clientHeaders = storageService.getItem(StorageKeys.clientHeaders);

  config.headers['X-Language'] = `${i18n.language}-US`;

  if (clientHeaders) {
    try {
      const clientHeadersObject = JSON.parse(clientHeaders);
      config.headers = {
        ...config.headers,
        ...clientHeadersObject,
      };
    } catch (e) {
      console.log(e);
    }
  }

  return config;
});

api.interceptors.request.use((config) => {
  (async () => {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return;
    }

    const notificationAuthToken = storageService.getItem(StorageKeys.notificationAuthToken);
    const deviceToken = storageService.getItem(StorageKeys.deviceToken);
    const token = `${Authorization}-${deviceToken}`;

    if (
      deviceToken &&
      deviceToken.length > 10 &&
      (!notificationAuthToken || notificationAuthToken !== token)
    ) {
      try {
        storageService.setItem(StorageKeys.notificationAuthToken, token);
        const response = await notifications.subscribe(deviceToken);

        // @ts-ignore
        if (response.code >= 300) {
          storageService.removeItem(StorageKeys.notificationAuthToken);
        }
      } catch (e) {
        storageService.removeItem(StorageKeys.notificationAuthToken);
        console.log(e);
      }
    }
  })();

  return config;
});

export default {
  api,
  auth,
  authV2,
  settings,
  search,
  markers,
  rides,
  notifications,
  getInformation,
  sendPushNotification,
  purchases,
  routes,
  lyftApi,
  worker,
  androidConfig,
};
